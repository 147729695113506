<template>
    <div class="content">
        <div class="line">
            <van-field v-model="videoUrl" type="textarea" rows="3" placeholder="请输入链接" class="line-input" :clearable="true"/>
        </div>
        <div class="line">
            <van-button type="info" @click="handleVideo" class="line-button" :loading="loadingShow">生成</van-button>
        </div>
        <div class="line-info" v-if="!fileAddress">{{logs}}</div>
        <div class="line" v-if="!!fileAddress">
            <a :href="fileAddress" target="_blank">下载</a>
        </div>
        <div class="line-info" v-if="!!fileAddress">
            {{fileAddress}}
        </div>
        <div class="line" v-if="!!fileAddress">
            <van-button class="line-button" type="info" @click="copyTextToClipboard(fileAddress)">复制链接</van-button>
        </div>
        <div class="line" v-if="!!fileAddress">
            <video :src="fileAddress" :poster="fileAddress + '?x-oss-process=video/snapshot,t_50,f_jpg,w_400,h_350'" controls="controls" width="100%"/>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'home',
    data() {
        return {
            logTime: 0,
            logs: null,
            // videoUrl: '6.46 复制打开抖音，看看【红鼻小丑的作品】  https://v.douyin.com/i6sjVPXq/ f@B.tR 06/15 fOk:/',
            videoUrl: '',
            loadingShow: false,
            fileAddress: '',
            // fileAddress: 'https://13490f.spjgzs.com:13490/oss/yt-dlp/uxeanpjc8.mp4',
            price: '',
            code: '',
            openid: '',
            device_type: '',
            user_id: '',
            alipay_code: '',
            trade_no: ''
        }
    },
    components: {
    },
    created() {
    },
    mounted() {
        document.title = '视频下载'
    },
    methods: {
        async handleVideo() {
            let params = {
                videoUrl: this.videoUrl
            }

            if (!params.videoUrl) {
                this.$toast('请输入链接')
                return false
            }
            // 6.46 复制打开抖音，看看【红鼻小丑的作品】  https://v.douyin.com/i6sjVPXq/ f@B.tR 06/15 fOk:/
            if (params.videoUrl.indexOf(' ') >=0) {
                console.log(params.videoUrl.split(' '))
                let _arr = params.videoUrl.split(' ')
                params.videoUrl = _arr.filter(item => {
                    return item.indexOf('http') >= 0
                })[0]
            }
            console.log(params)
            if (params.videoUrl.indexOf('http') <0) {
                this.$toast('请输入正确的链接')
                return false
            }
            // console.log(params)
            // return false
            this.loadingShow = true
            this.fileAddress = ''
            this.logs = `开始解析：${params.videoUrl}`
            this.logTime = 0
            let logInterval = setInterval(() => {
                this.logTime++
                this.logs = `开始解析：${params.videoUrl}，${this.logTime}秒`
            }, 1000)
            this.$util.ajax('/nas/ytDlp/getData', 'POST', params).then(res => {
                clearInterval(logInterval)
                this.logs = JSON.stringify(res)
                console.log(res.data.fileAddress)
                if (!!res.code && res.code === 200) {
                    if (params.videoUrl.includes('douyin')) {
                        this.fileAddress = res.data.fileAddress
                        this.loadingShow = false
                    }else {
                        setTimeout(() => {
                            this.fileAddress = res.data.fileAddress
                            this.loadingShow = false
                        }, 5000)
                    }
                }else {
                    this.loadingShow = false
                }


                // const url = window.URL.createObjectURL(blob);
                // const a = document.createElement('a');
                // a.style.display = 'none';
                // a.href = url;
                // a.download = 'video.mp4'; // 下载的文件名
                // document.body.appendChild(a);
                // a.click();
                // window.URL.revokeObjectURL(url);
                //
                // alert('视频已下载。请在下载完成后保存到相册。');
                //
                // // 选项：在页面上显示视频
                // const videoPlayer = document.getElementById('videoPlayer');
                // videoPlayer.src = url;
                // videoPlayer.style.display = 'block';
                // if (!!res.data.user_id) {
                //     localStorage.user_id = res.data.user_id
                //     this.user_id = res.data.user_id
                // }
            })
        },
        async copyTextToClipboard(text) {
            try {
                await navigator.clipboard.writeText(this.fileAddress);
                console.log('Text copied to clipboard');
                this.$toast('链接已复制到剪贴板')

            } catch (err) {
                console.error('Failed to copy: ', err);
                this.$toast('复制失败')

            }
        },
        async handleTest() {
            for (let i = 0; i <=10000; i++) {
                // console.log(i)

                let res = await this.$util.ajax('/nas/test', 'POST' ,{num: i})

                // console.log(res)
            }
        },

    }
}
</script>

<style scoped lang="less">
.content {
    width: 100%;
    box-sizing: border-box;
    padding: 0.6rem 0.3rem;
    .line {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.2rem;
        .line-input {
            border-bottom: 1px solid #f0f0f0;
            font-size: 0.18rem;
            color: #000;
        }
        .line-button {
            width: 100%;
        }
    }
    .line-info {
        padding-top: 0.2rem;
        word-break: break-word;
        font-size: 0.16rem;
        color: #666;
    }
}
</style>
